import React, { useEffect, useState } from "react";
import Layout from "@components/layouts/Layout";
import { RiRefreshLine } from "react-icons/ri";
import { toast } from "react-toastify";
import SmallBanner from "@components/common/SmallBanner";
import dayjs from "dayjs";
import axios from "axios";

// Importamos el idioma español para dayjs y lo activamos
require("dayjs/locale/es");
dayjs.locale("es");

// Activamos plugin de dayjs para mostrar la fecha en formato relativo
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const Component = ({ page = 1 }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            const toastId = toast.loading("Cargando datos...", {
                position: "bottom-center",
            });

            const rawData = await getAchievements({ page });
            setData(rawData);

            setLoading(false);
            toast.dismiss(toastId);
        }

        fetchData();
    }, [page]);

    return (
        <Layout
            seo={{
                title: "Logros",
                description: "Lorem ipsum dolor sit amet.",
            }}
        >
            <SmallBanner
                title="Logros"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                filename={"bgs/bgAchievements"}
            />

            {loading && <RenderLoading />}
            {data && <div className="container py-20 mx-auto">HOLA</div>}
        </Layout>
    );
};

const RenderLoading = () => {
    return (
        <div className="mx-auto container py-10 px-10 lg:px-0 font-montserrat">
            <div className="text-gray-500 uppercase flex flex-row space-x-5 items-center justify-center">
                <RiRefreshLine className="animate-spin w-10 h-10" />
                <span className="font-semibold text-base lg:text-lg">Cargando el contenido...</span>
            </div>
        </div>
    );
};

const getAchievements = async ({ page }) => {
    try {
        const url = `/.netlify/functions/achievements`;
        const params = {
            page: page || 1,
        };

        const options = {
            headers: {
                "Content-Type": "application/json",
                "Strapi-Response-Format": "v4",
            },
        };

        const { data } = await axios.post(url, params, options);

        return data?.achievements;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export default Component;
